<template>
  <v-app>
    <b-modal id="message" scrollable :title="$t('messages.send_message_to') + ' ' + emailTo " size="lg" hide-footer static centered no-close-on-backdrop>
      <div class="row">
        <div class="theMessage" :class="{theMessageCurrent: currentUserEmail === item.from }" v-for="item in singleMessage" :key="item.token">
          <span>{{ item.message }} <span class="theDate">{{ item.created_at }}</span></span>
        </div>
        <div class="col-sm-12 mb-sm-1">
          <textarea v-model="emailText" class="textarea form-control" rows="1" :placeholder="$t('messages.add_message')"></textarea>
        </div>
        <div class="col-sm-6 col-6 text-left">
          <div class="send-btn">
            <button type="submit" class="btn btn-danger" v-on:click="deleteMessage(emailTo, currentUserEmail)">{{ $t('messages.delete') }}</button>
          </div>
        </div>
        <div class="col-sm-6 col-6 text-right">
          <div class="send-btn">
            <button type="submit" class="btn btn-primary" v-on:click="sendMessageTo()">{{ $t('messages.send_message') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
    <v-card>
      <v-card-title>
        <div style="width: 100%">
          <v-text-field
            v-model="search"
            append-icon="ri-search-line"
            :label="$t('messages.search')"
            single-line
            hide-details
          ></v-text-field>
        </div>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="notifications"
        :search="search"
        :footer-props="{
                  prevIcon: 'ri-arrow-left-line',
                  nextIcon: 'ri-arrow-right-line'
                }"
        @click:row="appendMessage"
      >
        <template v-slot:item.message="{ item }">
          <div class="message" :class="{ notRead: item.seen === 0 }">{{ item.message }}</div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import { core } from '@/config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  data () {
    return {
      currentUserEmail: '',
      emailTo: '',
      emailText: '',
      search: '',
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: this.$t('messages.from'),
          align: 'start',
          sortable: true,
          value: 'with',
          width: '10%'
        },
        { text: this.$t('messages.message'), value: 'message', width: '80%' },
        { text: this.$t('messages.date'), value: 'sent', width: '10%' }
      ],
      notifications: [],
      singleMessage: [],
      apiToken: localStorage.getItem('api_token') || ''
    }
  },
  mounted () {
    core.index()
    this.getTheMessages()
  },
  methods: {
    appendMessage (item) {
      this.getTheMessagesFrom(item.from, item.to)
      // set as read
      this.emailTo = item.with
      this.emailText = ''
      this.setSeenStatus(item.with)
      this.getTheMessages()
      this.$bvModal.show('message')
    },
    getTheMessages () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_MESSAGES)
        .then((response) => {
          if (response.data.status === 'return') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.notifications = response.data.messages
            this.currentUserEmail = response.data.currentUserEmail
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getTheMessagesFrom (from, to) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        from: from,
        to: to
      }
      Axios.post(API.API_GET_MESSAGES_FROM, postData)
        .then((response) => {
          if (response.data.status === 'return') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.singleMessage = response.data.messages
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    sendMessageTo () {
      if (this.emailText === '') {
        core.showSnackbar('error', this.$t('messages.not_empty'))
      } else {
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        const theMessage = {
          to: this.emailTo,
          message: this.emailText
        }
        Axios.post(API.API_SEND_MESSAGE, theMessage)
          .then((response) => {
            if (response.data.status === 'return') {
              core.showSnackbar('error', response.data.message)
            } else if (response.data.status === 'success') {
              core.showSnackbar('success', response.data.message)
              // refresh the message
              this.getTheMessagesFrom(this.emailTo, this.currentUserEmail)
              this.emailText = ''
              this.getTheMessages()
            } else if (response.data.status === 'upgrade') {
              this.$emit('upgrade_message', response.data.nextPlan)
              this.$bvModal.show('globalUpgrade')
            }
          })
          .catch((error) => {
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    setSeenStatus (from) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const apiData = {
        from: from
      }
      Axios.post(API.API_SET_MESSAGES_SEEN_STATUS, apiData)
        .then((response) => {
          if (response.data.status === 'return') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            // to do
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    deleteMessage (from, to) {
      this.$bvModal.msgBoxConfirm(this.$t('messages.delete_message'))
        .then(value => {
          if (value === true || value === 'true') {
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            const apiData = {
              from: from,
              to: to
            }
            Axios.post(API.API_DELETE_MESSAGES, apiData)
              .then((response) => {
                if (response.data.status === 'return') {
                  core.showSnackbar('error', response.data.message)
                } else if (response.data.status === 'success') {
                  this.$bvModal.hide('message')
                  this.getTheMessages()
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response && error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    }
  }
}
</script>

<style scoped>
.notRead{
  font-weight: bold;
}
.theMessage{
  width: 100%;
  margin-bottom: 10px;

}
.theMessage.theMessageCurrent{
  text-align: right;
}
.theMessage.theMessageCurrent > span{
  background: #69e384;
}
.theMessage > span{
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  color: #275ab7;
  display: inline-table;
}
.theDate{
  font-size:10px;
  color: black;
}
</style>
